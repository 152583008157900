@import 'styles/theme';

$iconWidth: 30px;

.Arrow {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  cursor: pointer;

  &.disabled {
    cursor: unset;
    opacity: 0.5;
    pointer-events: none;

    .icon {
      cursor: unset;
      opacity: 0.5;
      pointer-events: none;
    }
  }

  &.left {
    .icon {
      transform: rotate(180deg);
    }
  }

  .icon {
    display: block;
    width: $iconWidth;
    transition: ease 0.15s opacity;
  }
}
