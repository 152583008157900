@import 'styles/theme';

.GoldenTitle {
  text-align: center;
  text-transform: uppercase;
  padding-bottom: theme($spacings, 50);

  @include from('md') {
    border-bottom: 1px solid theme($colors, 'gray-93');
  }
}
