@import 'styles/theme';

.CoverPush {
  margin: theme($spacings, 100) 0;
  align-items: flex-start;

  @include from('l') {
    margin: theme($spacings, 200) 0 theme($spacings, 250);

    &.inversed {
      .imageWrapper {
        grid-row: 1;
        grid-column: 7 / -1;
        margin-left: 0;
        margin-right: calc(#{theme($desktop, 'gutter')} * -1);
      }

      .content {
        grid-row: 1;
        grid-column: 2 / span 5;
      }
    }
  }

  @include from('lg') {
    &.inversed {
      .content {
        grid-column: 2 / span 4;
      }
    }
  }

  .wrapper {
    align-items: flex-start;
  }

  &.coverIsLarge {
    .imageWrapper,
    .content {
      @include from('l') {
        position: static;
      }

      @include from('lg') {
        position: static;
      }

      @include from('xl') {
        position: sticky;
      }
    }

    .imageWrapper {
      display: block;
      margin: {
        left: calc(#{theme($mobile, 'gutter')} * -1);
        right: calc(#{theme($mobile, 'gutter')} * -1);
      }

      @include from('md') {
        grid-column: 1 / -1;
        margin: {
          left: 0;
          right: 0;
        }
      }

      @include from('md') {
        grid-column: 2 / -2;
      }

      @include from('xl') {
        grid-column: 1 / span 7;
        margin: {
          left: calc(#{theme($desktop, 'gutter')} * -1);
          right: 0;
        }
      }
    }

    .content {
      border-top: none;
      grid-row: 2;

      @include from('md') {
        grid-column: 2 / -2;
        text-align: center;

        .text {
          text-align: center;
        }
      }

      @include from('xl') {
        grid-column: 9 / -2;
        grid-row: 1;
        padding-top: theme($spacings, 60);
        text-align: left;

        .text {
          text-align: left;
        }
      }
    }

    &.inversed {
      .imageWrapper {
        @include from('xl') {
          grid-column: 6 / -1;
          margin: {
            right: calc(#{theme($desktop, 'gutter')} * -1);
            left: 0;
          }
        }
      }

      .content {
        @include from('xl') {
          grid-column: 2 / span 3;
        }
      }
    }
  }
}

.imageWrapper {
  position: static;
  grid-column: 1 / -1;

  @include from('l') {
    margin-left: calc(#{theme($desktop, 'gutter')} * -1);
    grid-column: 1 / span 6;
    position: sticky;
    top: 0;
  }
}

.content {
  position: static;
  padding-top: theme($spacings, 50);
  text-align: center;
  grid-column: 1 / -1;

  @include from('l') {
    grid-column: 8 / -1;
    padding-top: theme($spacings, 100);
    border-top: solid 1px theme($colors, 'gray-93');
    text-align: left;
    position: sticky;
    top: 0;
  }

  @include from('lg') {
    grid-column: 8 / -2;
  }
}

.text {
  text-align: center;

  &:not(:first-child) {
    margin-top: theme($spacings, 20);

    @include from('l') {
      margin-top: theme($spacings, 30);
    }
  }

  @include from('l') {
    text-align: left;
    // text-indent: theme($spacings, 20);

    &:not(:last-child) {
      margin-bottom: theme($spacings, 20);
    }
  }
}

.cta {
  display: inline-block;
  margin-top: theme($spacings, 30);
}
