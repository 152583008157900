@use "sass:map";
@import 'styles/theme';

.SquaredCta {
  text-align: center;
  position: relative;
  cursor: pointer;
  overflow: hidden;
  box-sizing: border-box;
  transition: background-color 0.2s linear, color 0.2s linear;

  &:disabled {
    opacity: 0.35;
  }

  &.fullWidth {
    display: block;
    width: 100%;
  }

  &.darkTheme {
    border: 1px solid theme($colors, 'black');
    background-color: theme($colors, 'black');

    &.hovered {
      background-color: theme($colors, 'light');
    }
  }

  &.transparentTheme {
    border: 1px solid theme($colors, 'black');

    &.hovered {
      background-color: theme($colors, 'black');
    }
  }

  &.lightTheme {
    border: 1px solid theme($colors, 'gray-93');

    &.hovered {
      background-color: theme($colors, 'gray-93');
    }
  }

  &.loading {
    pointer-events: none;
  }
}

.SquaredCta,
.childrenHover {
  box-sizing: border-box;
  padding: map-get($spacings, 10) map-get($spacings, 20);
}

.isLoading {
  opacity: 0;
}

.childrenHover {
  transform: translate3d(0%, -100%, 0);
}

.spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 20px;
}

.line {
  position: absolute;
  bottom: 0px;
}
