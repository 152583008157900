@import 'styles/theme';

.ProductCard {
  position: relative;
  display: block;
  margin-bottom: theme($spacings, 30);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  @include switchDesktopMobileClasses();

  @include from('md') {
    margin-bottom: 0;
  }

  .productInfo {
    text-align: center;
    position: relative;
    margin-top: theme($spacings, 20);
    text-align: center;

    @include from('md') {
      height: theme($spacings, 90);
      text-align: left;
    }
  }

  .productMaterial {
    @include from('md') {
      display: none;
    }
  }

  .mainImage {
    display: none;

    @include from('md') {
      display: block;
    }

    &.mobileImage {
      display: block;

      @include from('md') {
        display: none;
      }
    }
  }

  .variants {
    display: none;

    @include from('md') {
      display: flex;
      flex-direction: row;
      justify-content: center;
      margin-bottom: theme($spacings, 15);
      justify-content: flex-start;
    }

    .variant {
      position: relative;
      width: theme($spacings, 50);
      height: theme($spacings, 50);
      margin-right: theme($spacings, 10);
      overflow: hidden;
      background-color: theme($colors, 'gray-93');

      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 1px;
        background: black;
        transform: translate3d(0, 2px, 0);
        transition: 0.2s linear;
      }

      &.active {
        &:after {
          transform: translate3d(0, 0, 0);
        }
      }
    }
  }

  .wishlist {
    display: none;
    position: absolute;
    top: 0px;
    right: 0px;
    width: theme($spacings, 14);
    padding: theme($spacings, 20);
  }

  &.isHover {
    @include from('md') {
      .wishlist {
        display: block;
      }
    }
  }

  .spinnerWrapper {
    position: relative;

    .spinner {
      position: absolute;
      top: theme($spacings, 3);
      left: theme($spacings, 10);
      width: theme($spacings, 10);
    }
  }
}
