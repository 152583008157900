@import 'styles/theme';

.ProductsGrid {
  padding: 0 calc(#{theme($mobile, 'gutter')} * -1);
  margin-bottom: theme($spacings, 30);
  overflow: hidden;

  .productsContainer {
    width: 100%;
    grid-column: 1 / -1;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: theme($mobile, 'gap');

    @include from('md') {
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-column-gap: theme($desktop, 'gap');
    }
  }

  @include from('md') {
    padding: 0 calc(#{theme($desktop, 'gutter')});
  }

  .product {
    @include from('md') {
      margin-bottom: theme($spacings, 30);
    }
  }
}
