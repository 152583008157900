@import 'styles/theme';

.ProductsSlider {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: theme($spacings, 100) 0;
  max-width: 100vw;

  @include from('md') {
    margin: theme($spacings, 250) 0;
  }
}

.wrapper {
  pointer-events: none;
}

.header {
  grid-column: 1 / -1;
  margin-bottom: theme($spacings, 30);

  @include from('md') {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    margin-bottom: theme($spacings, 50);
  }
}

.titles {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  @include from('md') {
    flex-direction: row;
    justify-content: flex-start;
    text-align: left;
  }
}

.title {
  opacity: 0.3;
  cursor: pointer;

  &.selected {
    cursor: unset;
    opacity: 1;
  }

  &:not(:first-child) {
    margin: {
      top: theme($spacings, 10);
      left: theme($spacings, 15);
    }
  }

  @include from('md') {
    &:not(:first-child) {
      margin: {
        top: 0;
        left: theme($spacings, 30);
      }
    }
  }
}

.sliderControls {
  display: none;

  @include from('md') {
    display: flex;
  }

  .arrowRight {
    margin-left: theme($spacings, 10);
  }
}

.gridWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: theme($spacings, 30);

  @include from('md') {
    display: none;
  }

  .button {
    margin-top: theme($spacings, 10);

    @include from('md') {
      margin-top: theme($spacings, 50);
    }
  }
}

.sliderWrapper {
  display: none;

  @include from('md') {
    display: grid;
    margin-bottom: theme($spacings, 30);
  }

  .slider {
    grid-column: 1 / -1;

    .item {
      margin-right: theme($desktop, 'gap');
      @include gridColumnWidth(3, $desktop);
    }
  }
}

.spinner {
  @include gridColumnWidth(3, $desktop);

  svg {
    width: 30px;
    height: 30px;
    top: calc(50% - 15px);
    left: calc(50% - 15px);
  }
}
