@import 'styles/theme';

.ProductDypticSlider {
  overflow: hidden;
  margin: theme($spacings, 100) auto;

  @include from('md') {
    margin: theme($spacings, 250) auto;
  }

  .wrapperWithLink {
    display: block;
  }

  .coverWrapper {
    position: relative;
    overflow: hidden;
    margin: 0 calc(#{theme($mobile, 'gutter')} * -1);
    grid-column: 1 / -1;

    @include from('md') {
      grid-column: 1 / 7;
      margin: 0 0 0 calc(#{theme($desktop, 'gutter')} * -1);
    }

    .content {
      position: absolute;
      margin-left: theme($mobile, 'gutter');
      bottom: theme($spacings, 50);

      @include from('md') {
        margin-left: theme($desktop, 'gutter');
      }
    }
  }

  .sliderWrapper {
    grid-column: 1 / -1;
    display: flex;
    flex-direction: column;
    margin: theme($spacings, 50) calc(#{theme($mobile, 'gutter')} * -1) 0;
    padding: 0 theme($mobile, 'gutter');

    @include from('md') {
      flex-direction: row;
      align-items: center;
      justify-content: center;
      padding: 0;
      grid-column: 7 / -1;
      margin: 0 calc(#{theme($desktop, 'gutter')} * -1) 0 0;
    }

    .sliderWidth {
      @include setPropertyValue(
        (
          'values': $mobile,
          'propertyName': 'width',
          'gutters': 1,
          'gaps': 0,
          'columns': 3,
        )
      );
      @include from('md') {
        width: getGridColumnWidth(3, $desktop);
        overflow: hidden;
        margin: 0 theme($spacings, 50);
      }
    }

    .slider {
      @include from('md') {
        padding: 0;
        width: getGridColumnWidth(3, $desktop);
        overflow: hidden;
      }

      .product {
        @include setPropertyValue(
          (
            'values': $mobile,
            'propertyName': 'width',
            'gutters': 1,
            'gaps': 0,
            'columns': 3,
          )
        );

        margin-right: theme($mobile, 'gap');
        margin-bottom: 0;

        @include from('md') {
          margin-right: 0;
          width: getGridColumnWidth(3, $desktop);
        }
      }
    }

    .arrow {
      display: none;

      @include from('md') {
        display: flex;
      }

      &.arrowRight {
        margin-left: calc(#{theme($spacings, 10)} * -1);
      }

      &.arrowLeft {
        margin-right: calc(#{theme($spacings, 10)} * -1);
      }
    }

    .controlsWrapper {
      margin: theme($spacings, 50) auto 0;

      @include from('md') {
        display: none;
      }
    }
  }

  &.inversed {
    @include from('md') {
      .coverWrapper {
        grid-column: 7 / -1;
        margin: 0 calc(#{theme($desktop, 'gutter')} * -1) 0 0;
      }
      .sliderWrapper {
        grid-row: 1;
        grid-column: 1 / 7;
        margin: 0 0 0 calc(#{theme($desktop, 'gutter')} * -1);
      }
    }
  }
}
